import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import airBnb from "../images/site_art/airbnb.svg"
import slack from "../images/site_art/slack.svg"
import dropbox from "../images/site_art/dropbox.svg"
import styled from "styled-components"

const Hero = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 10px;
`

const MainTagline = styled.h1`
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: center;
  justify-items: center;
  line-height: 1;
  text-align: center;

  @media only screen and (min-width: 375px) {
    padding-top: 20px;
    font-size: 40px;
  }

  @media only screen and (min-width: 700px) {
    padding-top: 100px;
    font-size: 100px;
  }

  @media only screen and (min-width: 1024px) {
    padding-top: 100px;
    font-size: 100px;
  }
`

const SubHeadline = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: center;
  justify-items: center;

  p {
    padding: 0px;
    margin: 0px;
    max-width: 800px;
    text-align: center;
  }

  @media only screen and (min-width: 375px) {
    p {
      font-size: 16px;
      line-height: 1.3;
    }
  }

  @media only screen and (min-width: 768px) {
    p {
      font-size: 24px;
      line-height: 1.3;
    }
  }

  @media only screen and (min-width: 1024px) {
    p {
      font-size: 26px;
      line-height: 1.3;
    }
  }
`

const FeaturedSection = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: center;
  justify-items: center;

  @media only screen and (min-width: 375px) {
    p {
      padding: 0px;
      margin: 0px;
      max-width: 420px;
      font-size: 16px;
      line-height: 1.3;
      text-align: center;
      color: #000;
    }

    h3 {
      color: #000;
      padding: 0px;
      margin: 0px 0px 10px 0pxß;
      max-width: 420px;
      font-size: 26px;
      line-height: 1.2;
      text-align: center;
    }
  }
  @media only screen and (min-width: 700px) {
    p {
      padding: 0px;
      margin: 0px;
      max-width: 600px;
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
      color: #000;
    }

    h3 {
      color: #000;
      padding: 0px;
      margin: 0px;
      max-width: 600px;
      font-size: 28px;
      line-height: 1.8;
      text-align: center;
    }
  }
`

const TeamLogos = styled.div`
  margin: 0 auto;
  padding: 40px 0px 0px 0px;
  width: 94%;

  @media (min-width: 780px) {
    width: 800px;
  }

  div {
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    justify-items: center;
  }
`

const ActionSection = styled.div`
  width: 100%;
  padding: 20px 0px;
`

const BackgroundHero = styled.div`
  width: 100%;
  padding: 0px 0px 40px 0px;
  margin-bottom: 80px;
  background: url("https://res.cloudinary.com/designhunt/image/upload/v1559309100/Design%20Hunt%20Site/hero.jpg");
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  opacity: 0.8;

  @media only screen and (min-width: 375px) {
    height: 200px;
  }
  @media only screen and (min-width: 700px) {
    height: 400px;
  }
`

const WhiteSection = styled.div`
  width: 100%;
  padding: 80px 0px 80px 0px;
  background-color: #fff;
`

const Section = styled.div`
  width: 100%;
  padding: 80px 0px 80px 0px;
  background-color: #000;

  p {
    color: #fff;
  }
  h3 {
    color: #fff;
  }
`

const ActionButtons = styled.div`
  margin: 0 auto;
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-items: center;

  a {
    transition: 300ms all cubic-bezier(0.4, 0, 0.6, 1);
    font-size: 20px;
    color: #09f;
  }

  a:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const Copyright = styled.p`
  max-width: 800px;
  font-size: 12px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: center;
  justify-items: center;
  padding-bottom: 10px;
`

const SocialIcons = styled.div`
  max-width: 100px;
  font-size: 12px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-items: center;
  padding-bottom: 10px;

  a svg {
    color: #666;
    transition: all 0.2s ease;
  }
  a:hover svg {
    color: #09f;
  }
`

const FooterWrap = styled.div`
  color: #666;
  padding: 100px 0;
  background-color: black;
  width: 100%;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Design Hunt: Creative inspiration for everyone" />
    <Hero>
      <MainTagline>Creative inspiration for everyone. </MainTagline>
      <SubHeadline>
        <p>
          Discover the <b>best content</b> from top creative resources
          <br />
          and our extensive hand-picked collections.
        </p>
      </SubHeadline>
      <ActionSection>
        <ActionButtons>
          <a
            href="https://chrome.google.com/webstore/detail/design-hunt/ilfjbjodkleebapojmdfeegaccmcjmkd?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome Extension
          </a>
          <a
            href="https://itunes.apple.com/us/app/design-hunt/id968588816?mt=8"
            target="_blank"
            rel="noopener noreferrer"
          >
            iOS App
          </a>
        </ActionButtons>
      </ActionSection>
    </Hero>
    <BackgroundHero />
    <WhiteSection>
      <FeaturedSection>
        <h3>Leading product teams are using Design Hunt</h3>
        <p>
          Everyday thousands of creatives around the globe are using Design Hunt
          as part of their workflow. Including our plugins for Chrome, Framer X
          and Adobe XD.
        </p>
      </FeaturedSection>

      <div className="video_player">
        <img
          src="https://res.cloudinary.com/designhunt/image/upload/v1559309100/Design%20Hunt%20Site/designhunt.jpg"
          width="80%"
          alt="Design Hunt Graphic"
        />
      </div>
      <TeamLogos>
        <div>
          <img src={airBnb} alt="Air BnB Logo" />
          <img src={slack} alt="Slack Logo" />
          <img src={dropbox} alt="Dropbox Logo" />
        </div>
      </TeamLogos>
    </WhiteSection>
    <Section>
      <FeaturedSection>
        <h3>Design Hunt Plugins</h3>
        <p>
          Our new interactive component for Framer X & Adobe XD allows you to
          use our curated inspirations in your design process. Grab it today.
        </p>
        <ActionSection>
          <ActionButtons>
            <a
              href="https://store.framer.com/package/talkaboutdesign/design-hunt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Framer X
            </a>
            <a
              href="https://www.adobe.com/products/xd/resources.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adobe XD
            </a>
          </ActionButtons>
        </ActionSection>
        <div className="video_player">
          <img
            src="https://res.cloudinary.com/designhunt/image/upload/v1559309100/Design%20Hunt%20Site/framer.jpg"
            width="80%"
            alt="Design Hunt Graphic"
          />
        </div>
        <p>Coming soon to Sketch and InVision Studio.</p>
      </FeaturedSection>
    </Section>
    <FooterWrap>
      <SocialIcons>
        <a
          href="https://twitter.com/designhuntapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.426"
            height="16"
            alt="Twitter"
          >
            <g>
              <path
                d="M 19.426 1.894 C 18.712 2.214 17.943 2.431 17.137 2.53 C 17.96 2.03 18.592 1.239 18.889 0.295 C 18.12 0.758 17.266 1.095 16.359 1.275 C 15.632 0.491 14.595 0 13.449 0 C 11.248 0 9.463 1.808 9.463 4.039 C 9.463 4.355 9.497 4.664 9.566 4.96 C 6.251 4.791 3.317 3.183 1.351 0.739 C 1.008 1.336 0.81 2.031 0.81 2.77 C 0.81 4.171 1.515 5.408 2.585 6.13 C 1.931 6.111 1.317 5.929 0.779 5.627 L 0.779 5.678 C 0.779 7.634 2.153 9.266 3.977 9.638 C 3.643 9.729 3.291 9.779 2.928 9.779 C 2.671 9.779 2.422 9.754 2.178 9.707 C 2.685 11.312 4.155 12.48 5.902 12.513 C 4.537 13.595 2.818 14.239 0.951 14.239 C 0.629 14.239 0.311 14.222 0 14.184 C 1.761 15.331 3.857 16 6.109 16 C 13.44 16 17.449 9.845 17.449 4.507 C 17.449 4.333 17.446 4.158 17.438 3.984 C 18.215 3.415 18.891 2.704 19.426 1.893"
                fill="currentColor"
              />
            </g>
          </svg>
        </a>

        <a
          href="https://www.facebook.com/designhuntapp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            alt="Facebook"
          >
            <g>
              <path
                d="M 7 4 L 17 4 C 18.657 4 20 5.343 20 7 L 20 17 C 20 18.657 18.657 20 17 20 L 7 20 C 5.343 20 4 18.657 4 17 L 4 7 L 4 7 C 4 5.343 5.343 4 7 4 Z M 15 14.462 L 16.556 14.462 L 17 12.385 L 15 12.385 L 15 11 C 14.895 10.413 15.06 9.993 15.889 10.077 L 17 10.077 L 17 8 C 16.812 8.058 16.166 8 15.444 8 C 13.847 8 12.774 8.994 12.778 10.769 L 12.778 12.385 L 11 12.385 L 11 14.462 L 12.778 14.462 L 12.778 20 L 15 20 Z"
                fill="currentColor"
              />
            </g>
          </svg>
        </a>
        <a
          href="https://www.youtube.com/channel/UCcKWVezaQOXlMVVrVesYQuQ"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            alt="YouTube"
          >
            <path
              d="M10 15V9l5 3.01zm10.82-6.98s-.176-1.372-.715-1.976c-.685-.793-1.452-.796-1.804-.842C15.783 5 12.005 5 12.005 5h-.008S8.218 5 5.7 5.202c-.353.046-1.12.05-1.805.842-.54.604-.715 1.976-.715 1.976S3 9.63 3 11.24v1.512c0 1.61.18 3.22.18 3.22s.175 1.373.715 1.977c.685.792 1.584.767 1.985.85 1.44.153 6.12.2 6.12.2s3.782-.006 6.3-.208c.353-.046 1.12-.05 1.805-.843.54-.605.715-1.977.715-1.977s.18-1.61.18-3.22V11.24c0-1.61-.18-3.22-.18-3.22z"
              fill="currentColor"
            />
          </svg>
        </a>
      </SocialIcons>
      <Copyright>
        Copyright © 2018 Talkabout Design. All rights reserved.
      </Copyright>
    </FooterWrap>
  </Layout>
)

export default IndexPage
